<template>
  <div>
    <survey :survey="survey"></survey>
  </div>
</template>

<script>
import * as SurveyVue from "survey-vue";
import "survey-vue/modern.css";
import surveyActions from "@/mixins/surveyActions.mixin";

export default {
  name: "TB-Survey",
  mixins: [surveyActions],
  props: {
    surveyPollData: Object,
  },
  data() {
    return {
      survey: "",
      surveyStartTime: 0,
      originalGoNextPageAutomatic: false,
    };
  },
  beforeMount() {
    this.init();
  },
  mounted() {
    this.injectStyles();
  },
  methods: {
    init() {
      this.setSurveyStartTime();
      this.applySurveyTheme();
      this.survey = new SurveyVue.Model(this.surveyPollData.content);
      this.originalGoNextPageAutomatic = this.surveyPollData.content.goNextPageAutomatic || false;
      this.survey.onCompleting.add((survey, options) => {
        const completeBtn = document.querySelector(".sv-footer__complete-btn");
        const prevBtn = document.querySelector(".sv-footer__prev-btn");
        completeBtn.classList.add("tribeify-disabled");
        completeBtn.disabled = true;
        if (prevBtn) {
          prevBtn.classList.add("tribeify-disabled");
          prevBtn.disabled = true;
        }

        this.couponEmail = document.querySelector('[aria-label="Email"]')?.value;
        this.onCompleteSurvey(survey, options);
      })
      this.survey.showQuestionNumbers = "off";
      this.survey.firstPageIsStarted = true;

      // this.createWelcomePage();
      this.survey.onAfterRenderSurvey.add(() => {
        document
          .querySelector(".tribeify-survey-welcome")
          .classList.remove("tribeify-survey-welcome");
        this.updateIntroductionPage(this.surveyPollData);
      });
      this.survey.onCurrentPageChanged.add((sender, option) => {
        this.doOnCurrentPageChanged(sender, option)
        this.initCtaButtonSetting(this.survey);
      });
      this.survey.onAfterRenderQuestion.add((survey, options) => {
        this.updateQuestionTitleMergeTagData(options);
      })
    },
    applySurveyTheme() {
      const defaultThemeColors = SurveyVue.StylesManager.ThemeColors["modern"];
      defaultThemeColors["$radio-checked-color"] = "#fff";
      defaultThemeColors["$main-color"] = this.$brandAttributes.primaryColor;
      defaultThemeColors[
        "$main-hover-color"
      ] = this.$brandAttributes.primaryColor;
      defaultThemeColors["$primary-color"] = this.$brandAttributes.primaryColor;
      defaultThemeColors[
        "$primary-hover-color"
      ] = this.$brandAttributes.primaryColor;
      defaultThemeColors[
        "$primary-icon-color"
      ] = this.$brandAttributes.primaryColor;
      defaultThemeColors["$text-color"] = this.$brandAttributes.primaryColor;
      defaultThemeColors[
        "$secondary-color"
      ] = this.$brandAttributes.primaryColor;
      defaultThemeColors["$body-container-background-color"] = "#fff";
      SurveyVue.StylesManager.applyTheme("modern");
      SurveyVue.cssType = "modern";
    },
    async onCompleteSurvey(survey, options) {
      if (true === survey.__isvalid) {
        delete survey.__isvalid;
        return;
      }
      options.allowComplete = false;
      const data = {
        content: survey.data,
        survey: this.surveyPollData.id,
        email: this.getAdvocateEmail,
        time_spent: this.getSpentTime(),
      };
      if (undefined === data.email && this.couponEmail) {
        data.email = this.couponEmail;
      }

      try {
        const surveyResult = await this.sendSurveyResult(data);
        options.allowComplete = true;
        survey.__isvalid = true;
        setTimeout(() => {
          survey.completedHtml = this.updateFinalPageMergeTagData(
            this.surveyPollData,
            surveyResult.data.coupon_code
          )
          this.surveyPollData.content.completedHtml = survey.completedHtml;
          const completedHtmlEl = document.querySelector('.sv-completedpage');
          completedHtmlEl.innerHTML = survey.completedHtml;
          if (surveyResult.data.coupon_code) {
            let divElm = document.createElement('a');
            let btnShopNow = document.createElement('a');
            btnShopNow.href = `${window.config.SHOP_URL}?couponCode=${surveyResult.data.coupon_code}`;
            btnShopNow.className = "btn-shop-now btn btn-lg";
            btnShopNow.style.cssText = "color: #FFF !important";
            btnShopNow.innerText = 'Shop Now';
            divElm.style.setProperty('align-items', 'center');
            divElm.append(btnShopNow)
            completedHtmlEl.appendChild(divElm);
          }
          this.$emit("survey-completed", surveyResult.data);
        }, 1000)
      } catch (error) {
        this.initSendSurveyResultCatchError(error);
      }

      if (true === survey.__isvalid) {
        survey.doComplete();
        const intervalId = setInterval(() => {
          const completedHtmlEl = document.querySelector('.sv-completedpage');
          if (null === completedHtmlEl) {
            return;
          }
          clearInterval(intervalId)
          completedHtmlEl.innerHTML = '<div class="mt-3">Completing...</div>';
        })
      }
      // this.$emit("showSurveyCompleteMessage", survey);
    },
    initSendSurveyResultCatchError(error) {
      const errorData = error.response?.data || {};
      let errorElement = document.createElement('div');

      if (undefined !== errorData.email) {
        this.removeExistingErrorMessageElement();
        errorElement.innerHTML = errorData.email;
        document.querySelector('[type="email"]').parentElement.classList.add('email-exists')
        document.querySelector('[type="email"]').parentElement.appendChild(errorElement)
      } else {
        this.removeExistingErrorMessageElement();
        errorElement.innerHTML = "An error has occur while submitting data.";
        errorElement.classList.add('error-message');
        let svFooter = document.querySelector('.sv-footer');
        svFooter.parentNode.insertBefore(errorElement, svFooter);
      }
    },
    removeExistingErrorMessageElement() {
      let messageError = document.querySelector('.error-message');
      if (null != messageError) {
        messageError.remove();
      }
      let emailError = document.querySelector('.email-exists div');
      if (null != emailError) {
        emailError.remove();
      }
    },
    setSurveyStartTime() {
      this.surveyStartTime = new Date().getTime();
    },
    getSpentTime() {
      return new Date().getTime() - this.surveyStartTime;
    },
    getBrandStyles() {
      return {
        "sv-checkbox--checked, .sv-radio--checked": {
          "background-color": this.$brandAttributes.primaryColor,
          color: this.$textColorBasedOnBgColor,
          "border-color": `${this.$brandAttributes.primaryColor} !important`,
        },
        ".tribeify-modal .sv-container-modern": {
          color: this.$brandAttributes.primaryColor,
        },
        ".sv-checkbox--checked .sv-checkbox__svg, .sv-previous-button-label": {
          fill: `${this.$textColorBasedOnBgColor} !important`,
        },
        ".sv-container-modern": {
          color: `${this.surveyPollData.content.textColor
            ? this.surveyPollData.content.textColor
            : this.$brandAttributes.primaryColor
            }`,
        },
        ".sv-container-modern .sv-body .sv-page": {
          color: `${this.surveyPollData.content.textColor
            ? this.surveyPollData.content.textColor
            : this.$brandAttributes.primaryColor
            }`,
        },
        ".sv-container-modern .sv-completedpage *": {
          color: `${this.surveyPollData.content.textColor
            ? this.surveyPollData.content.textColor
            : this.$brandAttributes.primaryColor
            } !important`,
        },
        ".sv-btn, .sv-comment.sv-question__other": {
          color: `${this.$textColorBasedOnBgColor} !important`,
        },
        ".sv-footer input": {
          "font-size": "1.3rem",
        },
        ".sv-header__text h3": {
          "font-family": `${this.$brandAttributes.headingFont} !important`,
        },
        ".sv-btn": {
          "font-family": this.$brandAttributes.headingFont,
        },
        ".sv-question__title": {
          "font-family": this.$brandAttributes.bodyCopyFont,
          color: `${this.surveyPollData.content.textColor
            ? this.surveyPollData.content.textColor
            : this.$brandAttributes.primaryColor
            } !important`,
        },
        ".sv-item__control-label": {
          "font-family": this.$brandAttributes.bodyCopyFont,
          "font-size": "20px",
        },
        ".sv-question__other": {
          "font-family": this.$brandAttributes.bodyCopyFont,
        },
        ".tiribeify-survey-complete-notification .btn-primary": {
          background: this.$brandAttributes.primaryColor,
        },
        ".sv-dropdown:focus": {
          "background-image": `url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23${this.$primaryColorAfterHash};%7D%0A%3C/style%3E%3Cpolygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/%3E%3C/svg%3E ");`,
        },
      };
    },
    getBrandStylesContent() {
      const brandStyles = this.getBrandStyles();
      return Object.keys(brandStyles).reduce((cssContent, selector) => {
        let styleProperty = `${selector} { `;
        styleProperty += Object.keys(brandStyles[selector]).reduce(
          (properties, property) =>
            properties + `${property}: ${brandStyles[selector][property]};`,
          ""
        );
        styleProperty += `} `;
        return cssContent + styleProperty;
      }, "");
    },
    injectStyles() {
      document
        .querySelector(".tribeify-modal")
        .insertAdjacentHTML(
          "afterbegin",
          `<style>${this.getBrandStylesContent()}</style>`
        );
    },
    doOnCurrentPageChanged(sender, option) {
      sender.onAfterRenderPage.add((sender) => {
        this.initSurveyCtaQuestionTypeStyle(this.surveyPollData);

        if (!option.oldCurrentPage) return;
        if (sender.isFirstPage) {
          this.removePrevArrow();
          return;
        }
        this.addPrevArrow();
      });
    },
    createWelcomePage() {
      const welcomePage = this.survey.createNewPage("welcome page");
      this.survey.addPage(welcomePage, 0);
      this.survey.currentPageNo = 0;
    },
    removePrevArrow() {
      document.querySelector(".sv-previous-button-label") &&
        (document.querySelector(".sv-previous-button-label").style.display =
          "none");
    },
    addPrevArrow() {
      setTimeout(() => {
        const svFooter = document.querySelector(".sv-body__footer");
        let prevButtonLabel;
        const prevButton = document.querySelector(".sv-footer__prev-btn");
        prevButton.value = "";
        document.querySelector(".sv-previous-button-label")?.remove();
        prevButtonLabel = document.createElement("label");
        prevButtonLabel.classList.add("sv-previous-button-label");
        svFooter.prepend(prevButtonLabel);
        prevButtonLabel.appendChild(prevButton);
        this.renderArrowLinkIcon(prevButtonLabel);
      }, 5);

    },
    renderArrowLinkIcon(node) {
      const iconSvg = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "svg"
      );
      const iconPath1 = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "path"
      );
      const iconPath2 = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "path"
      );

      iconSvg.setAttribute("width", "24");
      iconSvg.setAttribute("height", "24");
      iconSvg.setAttribute("viewBox", "0 0 24 24");
      iconSvg.setAttribute("fill", "inherit");
      iconSvg.classList.add("sv-previous-button-svg-icon");

      iconPath1.setAttribute("d", "M0 0h24v24H0z");
      iconPath1.setAttribute("fill", "none");

      iconPath2.setAttribute(
        "d",
        "M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"
      );

      iconSvg.appendChild(iconPath1);
      iconSvg.appendChild(iconPath2);

      return node.appendChild(iconSvg);
    },
    updateIntroductionPage(surveyPollData) {
      if (surveyPollData?.content?.description) {
        let beforeElement = document.createElement("div");
        beforeElement.classList.add("survey-description-preview");
        beforeElement.innerHTML = "<b>" + surveyPollData.content?.title + "</b><br/>" + surveyPollData.content?.description;

        this.setIntroductionPageIntervalId = setInterval(() => {
          let parentElement = document.querySelector(".sv-body__page");
          if (null === parentElement) {
            return;
          }
          clearInterval(this.setIntroductionPageIntervalId);
          parentElement.insertBefore(beforeElement, parentElement.children[0]);
          if (undefined != parentElement.children[1]) {
            parentElement.children[1].style.textAlign = "center";
            let secondChildInnerText = parentElement.children[1].innerText;
            if (secondChildInnerText.includes("'Yes'")) {
              document.querySelector(".sv-footer__start-btn").value = "YES";
            }
          }
        }, 100)
      }
    },
    getRenderText(text, context) {
      let renderedText = text;
      Object.entries(context).map(([key, value]) => {
        renderedText = renderedText.replaceAll(`{{${key}}}`, value)
      });
      return renderedText;
    },
    updateFinalPageMergeTagData(surveyPollData, couponCode) {
      if (!surveyPollData?.content?.completedHtml) {
        return;
      }
      return this.getRenderText(surveyPollData.content.completedHtml, {
        offer_value: (surveyPollData.points / 100),
        coupon_code: couponCode,
        point: surveyPollData.points
      })
    },
    updateQuestionTitleMergeTagData(options) {
      let currentText = options.htmlElement.innerHTML;
      if (this.survey.isLastPage && currentText.includes("{{offer_value}}")) {
        let newText = this.getRenderText(options.htmlElement.innerHTML, {
          offer_value: (this.surveyPollData.points / 100),
        })
        options.htmlElement.innerHTML = newText;
      }
    },
    initSurveyCtaQuestionTypeStyle(surveyPollData) {
      if (0 < surveyPollData?.content?.pages.length) {
        let surveyPages = surveyPollData?.content?.pages;
        surveyPages.map(({ elements = [] }) => {
          elements.map((item) => {
            if (item.renderAs && "ctabutton" === item.renderAs) {
              Array.from(
                (document.querySelector(`.sv-question.sv-row__question[data-name="${item.name}"]`)?.querySelectorAll('.sv-radio__decorator') || []),
                el => el.style.display='none'
              )
              Array.from(
                (document.querySelector(`.sv-question.sv-row__question[data-name="${item.name}"]`)?.querySelectorAll('.sv-selectbase') || []),
                el => el.style.textAlign='center'
              )
              Array.from(
                (document.querySelector(`.sv-question.sv-row__question[data-name="${item.name}"]`)?.querySelectorAll('.sv-selectbase__label') || []),
                el => el.style.marginLeft='0px'
              )
            }
          })
        })
      }
    },
    initCtaButtonSetting(surveyObject) {
      // CTA button auto to next question automatic
      surveyObject.currentPage?.questions.map((question) => {
        if ('ctabutton' === question.getPropertyValue('renderAs')) {
          surveyObject.goNextPageAutomatic = true;
          return;
        }
        surveyObject.goNextPageAutomatic = this.originalGoNextPageAutomatic;
      })
      // End CTA button auto to next question automatic
    },
  },
};
</script>

<style lang="scss">
.sv-container-modern {
  font-size: 14px;
  position: relative;
  max-width: 600px;
  margin: auto;
  padding: 0 10px;

  .sv-container-modern__title {
    padding: 0;
    padding-top: 0;
    text-align: center;
    margin-bottom: 1.5em;
  }

  .sv-components-row>.sv-components-column--expandable {
    width: 100%;
  }
}

.sv-body {
  display: flex;
  flex-direction: column;

  .email-exists {
    position: relative;

    div:nth-child(2) {
      font-size: 0.8rem;
      color: rgb(213, 41, 1);
    }
  }

  .error-message {
    color: rgb(213, 41, 1);
    font-size: 1rem;
    text-align: center;
    margin-top: 2rem;
  }

  .sv-page {
    display: block;
    width: 100%;
    height: 100%;
    background-color: white;
    overflow: auto;
    margin-left: 0;
    margin-right: 0;

    .sv-vue-row-additional-div>div {
      min-width: unset !important;
    }

    .sv-page__title,
    .sv-page__description {
      padding-left: 0;
    }

    .sv-question__content {
      margin-left: 0;
      font-size: 1.3rem;

      .sv-rating {
        text-align: center;
      }

      .sv-rating__item-star>svg {
        opacity: 0.5;
      }

      .sv-rating__item-star--selected>svg {
        opacity: 1;
      }

      .sv-imagepicker {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .sv-imagepicker__item--inline {
          margin-right: 4%;
        }
      }
    }

    .sv-question__title--answer {
      background-color: unset;
    }

    .sv-item {
      border: 3px solid #e6e5eb;
      padding: 0.5em;

      .sv-radio__svg,
      .sv-checkbox__svg {
        border-color: #e3e3e5;
      }
    }

    .survey-description-preview {
      font-size: 1.3rem;
      text-align: center;
      margin-bottom: 20px;
    }

    .sv-radio--checked {
      background-color: var(--primary-color);
      color: #fff;
      border-color: var(--primary-color) !important;
    }

    .sv-multipletext {
      width: 80%;
    }
  }

  .sv-title {
    font-size: 1.5rem;
    line-height: 35px;
  }

  .sv-title,
  .sv-description {
    text-align: center;
    font-weight: normal;
  }

  .sv-previous-button-label {
    position: relative;
    margin-bottom: 0;

    .sv-previous-button-svg-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .btn-shop-now {
    width: 200px;
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 6px;
    margin-top: 20px;

    &:hover {
      background-color: var(--primary-color);
      color: #fff !important;
      opacity: 0.8;
    }
  }

  .sv-body__footer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    padding: 1rem 0;
    margin-top: 0;

    .sv-btn {
      border-radius: 6px;
      text-transform: uppercase;
      background-color: var(--primary-color);
      color: rgb(255, 255, 255) !important;
      margin: 10px;
    }

    .sv-footer__complete-btn,
    .sv-footer__next-btn,
    .sv-footer__previous-btn {
      float: none;
    }

    .sv-footer__previous-btn {
      max-width: 40px;
    }
  }
}
</style>
